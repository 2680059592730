<template>
  <div>
    <h1 class="ui header">
      <div class="content">
        <div class="two-block">
          <div
            v-if="fastEditionMode && form && canEditFeature"
            class="form ui half-block"
          >
            <input
              id="feature_detail_title_input"
              :value="form.title"
              type="text"
              required
              maxlength="128"
              name="title"
              @blur="updateTitle"
            >
          </div>
          <div
            v-else
            class="ellipsis"
          >
            {{ currentFeature.properties ?
              currentFeature.properties.title : currentFeature.id }}
          </div>

          <div
            id="feature-actions"
            class="ui icon compact buttons"
          >
            <div
              v-if="queryparams"
              class="fast_browsing"
            >
              <div>
                <div>
                  <strong>
                    Tri en cours:
                  </strong>
                  <span>
                    par&nbsp;{{ currentSort }}
                  </span>
                </div>
                <div>
                  <strong>
                    Filtre en cours:
                  </strong>
                  <span>
                    {{ currentFilters }}
                  </span>
                </div>
              </div>
              <span
                class="feature-count"
              >
                {{ parseInt($route.query.offset) + 1 }} sur {{ featuresCount }}
              </span>
              <button
                id="previous-feature"
                :class="['ui button button-hover-green tiny-margin', { disabled: queryparams.previous < 0 }]"
                data-tooltip="Voir le précédent signalement"
                data-position="bottom center"
                @click="toFeature('previous')"
              >
                <i
                  class="angle left fitted icon"
                  aria-hidden="true"
                />
              </button>
              <button
                id="next-feature"
                :class="[
                  'ui button button-hover-green tiny-margin',
                  { disabled: queryparams.next >= featuresCount }
                ]"
                data-tooltip="Voir le prochain signalement"
                data-position="bottom center"
                @click="toFeature('next')"
              >
                <i
                  class="angle right fitted icon"
                  aria-hidden="true"
                />
              </button>
            </div>
            <div>
              <button
                v-if="fastEditionMode && canEditFeature"
                id="save-fast-edit"
                :class="['ui button button-hover-orange tiny-margin', { disabled: false }]"
                data-tooltip="Enregistrer les modifications"
                data-position="bottom center"
                @click="fastEditFeature"
              >
                <i
                  class="save fitted icon"
                  aria-hidden="true"
                />
              </button>

              <router-link
                v-if="permissions && permissions.can_create_feature && 
                  (featureType && !featureType.geom_type.includes('multi'))"
                id="add-feature"
                :to="{
                  name: 'ajouter-signalement',
                  params: {
                    slug_type_signal: $route.params.slug_type_signal || featureType ? featureType.slug : '',
                  },
                }"
                class="ui button button-hover-green tiny-margin"
                data-tooltip="Ajouter un signalement"
                data-position="bottom center"
              >
                <i
                  class="plus icon"
                  aria-hidden="true"
                />
              </router-link>

              <router-link
                v-if="slugSignal && canEditFeature"
                id="edit-feature"
                :to="{
                  name: 'editer-signalement',
                  params: {
                    slug_signal: slugSignal,
                    slug_type_signal: $route.params.slug_type_signal || featureType ? featureType.slug : '',
                  },
                  query: $route.query
                }"
                class="ui button button-hover-orange tiny-margin"
                data-tooltip="Éditer le signalement"
                data-position="bottom center"
              >
                <i
                  class="inverted grey pencil alternate icon"
                  aria-hidden="true"
                />
              </router-link>

              <a
                v-if="canDeleteFeature && isOnline"
                id="currentFeature-delete"
                class="ui button button-hover-red tiny-margin"
                data-tooltip="Supprimer le signalement"
                data-position="bottom right"
                @click="$emit('setIsDeleting')"
              >
                <i
                  class="inverted grey trash alternate icon"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>

        <!-- <div class="ui hidden divider" /> -->

        <div class="sub header prewrap">
          <span
            v-if="fastEditionMode && canEditFeature && form"
            class="form ui half-block"
          >
            <textarea
              :value="form.description.value"
              name="description"
              rows="5"
              @blur="updateDescription"
            />
          </span>
          <span v-else-if="currentFeature && currentFeature.properties">
            {{ currentFeature.properties.description }}
          </span>
        </div>
      </div>
    </h1>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';


export default {

  name: 'FeatureHeader',

  props: {
    featuresCount : {
      type: Number,
      default: null,
    },
    slugSignal: {
      type: String,
      default: '',
    },
    featureType: {
      type: Object,
      default: () => {},
    },
    fastEditionMode: {
      type: Boolean,
      default: false,
    },
    isFeatureCreator: {
      type: Boolean,
      default: false,
    },
    canEditFeature: {
      type: Boolean,
      default: false,
    },
    canDeleteFeature: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState([
      'user',
      'isOnline',
    ]),
    ...mapState('feature', [
      'currentFeature',
      'form',
    ]),
    ...mapState('projects', [
      'project'
    ]),
    ...mapGetters([
      'permissions',
    ]),

    queryparams() {
      return this.$route.query.offset >= 0 ? {
        previous: parseInt(this.$route.query.offset) - 1,
        next: parseInt(this.$route.query.offset) + 1
      } : null;
    },

    currentSort() {
      const sort = this.$route.query.ordering;
      if (sort) {
        if (sort.includes('status')) {
          return 'statut';
        } else if (sort.includes('feature_type')) {
          return 'type de signalement';
        } else if (sort.includes('title')) {
          return 'nom';
        } else if (sort.includes('updated_on')) {
          return 'date de modification';
        } else if (sort.includes('creator')) {
          return 'auteur';
        } else if (sort.includes('last_editor')) {
          return 'dernier éditeur';
        }
      }
      return 'date de création';
    },
    
    currentFilters() {
      let filters = [];
      if (this.$route.query.feature_type_slug) filters.push('type de signalement');
      if (this.$route.query.status__value) filters.push('statut');
      if (this.$route.query.title) filters.push('titre');

      if (filters.length > 0) {
        return `par ${filters.join(', ')}`;
      } else {
        return 'désactivé';
      }
    }
  },

  methods: {
    toFeature(direction) {
      this.$emit('tofeature', {
        name: 'details-signalement-filtre',
        params: {
          slug_type_signal: this.currentFeature.properties.feature_type.slug,
        },
        query: {
          ...this.$route.query,
          offset: this.queryparams[direction]
        }
      });
    },

    updateTitle(e) {
      this.$store.commit('feature/UPDATE_FORM_FIELD', { name: 'title', value: e.target.value });
    },

    updateDescription(e) {
      this.$store.commit('feature/UPDATE_FORM_FIELD', { name: 'description', value: e.target.value });
    },

    fastEditFeature() {
      this.$emit('fastEditFeature');
    }
  }
};
</script>

<style lang="less">
#feature_detail_title_input {
  font-weight: bold;
  font-size: 2em;
  padding: .25em;
}
.two-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5em;
}
#feature-actions {
  flex-direction: column;
  > div {
    line-height: initial;
    &:last-of-type {
      text-align: right;
    }
  }
  > .fast_browsing {
    display: flex;
    align-items: center;
    margin-bottom: .25rem;
    span {
      margin-left: .1em;
    }
    span, div {
      font-size: 1rem;
      color: #666666;
      margin-right: 1rem;
      font-weight: normal;

      opacity: 1 !important;
    }
  } 
}

@media screen and (max-width: 700px) {
  .two-block {
    flex-direction: column-reverse;
  }
  #feature-actions.ui.buttons {
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>