import { render, staticRenderFns } from "./FeatureHeader.vue?vue&type=template&id=65661286"
import script from "./FeatureHeader.vue?vue&type=script&lang=js"
export * from "./FeatureHeader.vue?vue&type=script&lang=js"
import style0 from "./FeatureHeader.vue?vue&type=style&index=0&id=65661286&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports